import React from 'react';
import { Layout } from '../components/shared/Layout';

const DisclaimerPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Contact</h1>
            <h2>Any questions?</h2>
            <div className="pt-16 pb-8">
              <p>
                Mail us at{' '}
                <a href="mailto:info@delaplumes.nl">info@delaplumes.nl</a>
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
